import {Component, Input, OnInit} from '@angular/core';

// Da eliminare
@Component({
  selector: 'app-small-card',
  templateUrl: './small-card.component.html',
  styleUrls: ['./small-card.component.scss'],
})
export class SmallCardComponent implements OnInit {
  @Input() height = "70px"
  @Input() titolo = "produttore"
  @Input() backgroundColor = "#F1F0F0"
  @Input() image = "assets/images/cantina3.png"

  constructor() { }

  ngOnInit() {}

}
