import { Injectable } from '@angular/core';
import * as Keycloak from 'keycloak-ionic';
import {Consts} from './conts';
import {TranslateService} from '@ngx-translate/core';
import {Platform} from '@ionic/angular';
import {ApiService} from "./api.service";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class KcService {
  tokenChange: Subject<string> = new Subject<string>();
  public keycloak: Keycloak.KeycloakInstance;
  public userProfile: Keycloak.KeycloakProfile;
  redir = Consts.redirectMobile;
  redirReg = Consts.redirectMobileReg;
  constructor(private translate: TranslateService,private platform: Platform, private api: ApiService, private http: HttpClient) {

    this.keycloak = Keycloak({
      clientId: 'veronese',
      realm: 'Veronese',
      url: Consts.kcBaseUrl,
    });

    if (this.isDesktop()) {
      this.redir = Consts.redirectWeb;
      this.redirReg = Consts.redirectWebReg;
    }

    if (this.platform.is('ios')) {

      this.keycloak.init({
        adapter: 'capacitor',
        responseMode: 'query',
        // flow: 'standard',
        // pkceMethod:'S256',
        redirectUri: this.redir,
        // checkLoginIframe: false,
        enableLogging: true,
      });
    } else {
      this.keycloak.init({
        adapter: 'capacitor-native',
        responseMode: 'query',
        // flow: 'standard',
        // pkceMethod:'S256',
        redirectUri: this.redir,
        // checkLoginIframe: false,
        enableLogging: true,
      });
    }
  }

  isDesktop(): boolean {
    return !(this.platform.is('cordova') || this.platform.is('capacitor'));
    return !this.platform.is('android') || !this.platform.is('ios');
  }
  getToken(): string{
    return localStorage.getItem(Consts.tokenVeronese);
  }
  getRefreshToken(): string{
    return localStorage.getItem(Consts.refreshokenVeronese);
  }
  refreshToken(){
    const refresh = this.getRefreshToken();
    if(refresh != null && refresh.length > 0) {
      this.api.refresh(refresh).subscribe(res => {
        localStorage.setItem(Consts.tokenVeronese, res.access_token);
        console.log('app.component refresh token' + res.access_token);
      });
    }
  }
}
