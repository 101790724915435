import {Injectable} from '@angular/core';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';

import {Consts} from './conts';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from './api.service';
import {KcService} from './kc.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private translate: TranslateService,private api: ApiService, private kc: KcService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token =  localStorage.getItem(Consts.tokenVeronese);
    request = this.addTokenHeader(request, token);
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse /*&& !request.url.includes('auth/signin')*/ && error.status === 401) {
        // return this.handle401Error(request, next);

        const refresh = localStorage.getItem(Consts.refreshokenVeronese);
        if(refresh != null && refresh.length > 0) {
          this.api.refresh(refresh).subscribe(res => {
            console.log('interceptor onRefreshToken');
            localStorage.setItem(Consts.tokenVeronese, res.access_token);
            // localStorage.setItem(Consts.refreshokenVeronese, res.refresh_token)
            console.log('app.component refresh token' + res.access_token);
            return next.handle(this.addTokenHeader(request, res.accessToken));
          });
        }
        // return next.handle(this.addTokenHeader(request, token.accessToken));
      }

      return throwError(error);
    }));
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', 'Bearer '+token) });
  }


}
