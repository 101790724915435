import { Component, OnInit } from '@angular/core';
import {SharedModule} from "../../shared/shared.module";

@Component({
  selector: 'app-cantina-maps',
  templateUrl: './cantina-maps.component.html',
  styleUrls: ['./cantina-maps.component.scss'],
})
export class CantinaMapsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
