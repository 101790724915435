import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ApiService} from "../../api.service";
import {TranslateService} from "@ngx-translate/core";
//Da eliminare
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  @Input() page: string= '';

  constructor(private router: Router, private api: ApiService, private translate: TranslateService ) { }

  ngOnInit() {
  }

  navigateTo( page: string){
    this.router.navigate([page]);
  }

}
