import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import {ApiService} from "../../api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Consts} from "../../conts";

@Component({
  selector: 'app-map-modal-poi',
  templateUrl: './map-modal-poi.component.html',
  styleUrls: ['./map-modal-poi.component.scss'],
})
export class MapModalPoiComponent implements OnInit {
  id: string;
  nome: string;
  indirizzo: string;
  lat: string;
  lng: string;
  img: string;
  showImg = false;
  constructor(public modalCtrl: ModalController, public navParams: NavParams, private api: ApiService, private route: ActivatedRoute,
              private router: Router) {
    this.id = this.navParams.get('id');
    this.nome = this.navParams.get('nome');
    this.indirizzo = this.navParams.get('indirizzo');
    this.img = this.navParams.get('img');
    // if(this.img !== null && this.img !== ''){
    //   this.img = Consts.dirLogoPoi+this.img;
    // }
  }

  ngOnInit() {
    if(this.img !== null && this.img !== ''){
      this.showImg = true;
      this.img = Consts.dirLogoPoi+this.img;
    }
  }

}
