import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PreferitiService {
  static currentAvatar = 'prova';
  onChange: Subject<boolean> = new Subject<boolean>();
  constructor() { }
  onChangePreferiti(){
    this.onChange.next(true);
  }
}
