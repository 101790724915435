import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SwiperModule } from 'swiper/angular';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'vino/:id',
    loadChildren: () => import('./pages/vino/vino.module').then( m => m.VinoPageModule)
  },
  {
    path: 'cantina/:id',
    loadChildren: () => import('./pages/cantina/cantina.module').then( m => m.CantinaPageModule)
  },
  {
    path: 'esperienze',
    loadChildren: () => import('./pages/esperienze/esperienze.module').then( m => m.EsperienzePageModule)
  },
  {
    path: 'add-esperienza',
    loadChildren: () => import('./pages/add-esperienza/add-esperienza.module').then( m => m.AddEsperienzaPageModule)
  },
  {
    path: 'esperienza/:id',
    loadChildren: () => import('./pages/esperienza/esperienza.module').then( m => m.EsperienzaPageModule)
  },
  {
    path: 'maps',
    loadChildren: () => import('./pages/maps/maps.module').then( m => m.MapsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'produttori/:type',
    loadChildren: () => import('./pages/produttori/produttori.module').then( m => m.ProduttoriPageModule)
  },
  {
    path: 'articoli/:type',
    loadChildren: () => import('./pages/articoli/articoli.module').then( m => m.ArticoliPageModule)
  },
  {
    path: 'ricerca',
    loadChildren: () => import('./pages/ricerca/ricerca.module').then( m => m.RicercaPageModule)
  },
  {
    path: 'portale',
    loadChildren: () => import('./pages/portale/portale.module').then( m => m.PortalePageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'post/:id',
    loadChildren: () => import('./pages/post/post.module').then( m => m.PostPageModule)
  },
  {
    path: 'map-picker',
    loadChildren: () => import('./pages/map-picker/map-picker.module').then( m => m.MapPickerPageModule)
  },
  {
    path: 'servizi',
    loadChildren: () => import('./pages/servizi/servizi.module').then( m => m.ServiziPageModule)
  },
  {
    path: 'servizio/:id',
    loadChildren: () => import('./pages/servizio/servizio.module').then( m => m.ServizioPageModule)
  },
  {
    path: 'proposte/:type',
    loadChildren: () => import('./pages/proposte/proposte.module').then( m => m.PropostePageModule)
  },
  {
    path: 'muoversi',
    loadChildren: () => import('./pages/muoversi/muoversi.module').then( m => m.MuoversiPageModule)
  },
  {
    path: 'raggiungere',
    loadChildren: () => import('./pages/raggiungere/raggiungere.module').then( m => m.RaggiungerePageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'preferiti',
    loadChildren: () => import('./pages/preferiti/preferiti.module').then( m => m.PreferitiPageModule)
  },

  {
    path: 'strutture-ristorazione',
    loadChildren: () => import('./pages/strutture-ristorazione/strutture-ristorazione.module').then( m => m.StruttureRistorazionePageModule)
  },
  {
    path: 'strutture-ricettive',
    loadChildren: () => import('./pages/strutture-ricettive/strutture-ricettive.module').then( m => m.StruttureRicettivePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'percorso/:id',
    loadChildren: () => import('./pages/percorso/percorso.module').then( m => m.PercorsoPageModule)
  },







];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    SwiperModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
