import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {Prodotto} from "./model/prodotto";
import {Produttore} from "./model/produttore";
import {Poi} from "./model/poi";
import {Categoria} from "./model/categoria";
import {Articolo} from "./model/articolo";
import {Proposta} from "./model/proposta";
import {Preferito} from "./model/preferito";
import {Post} from "./model/post";
import {Consts} from "./conts";
import {Servizio} from "./model/servizio";

import { DBSQLiteValues, SQLiteDBConnection } from '@capacitor-community/sqlite';
import {DatabaseService} from "./database.service";
import {Platform} from "@ionic/angular";
import {Attributo} from "./model/attributo";
import {Image} from "./model/image";
import {Abbinamento} from "./model/abbinamento";
import {Premio} from "./model/premio";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // baseUrl =  'http://veronesedoc.it:8096';
  baseUrl = Consts.baseUrl;
  constructor(private http: HttpClient, private database: DatabaseService,private platform: Platform) { }

  isDesktop(): boolean {
    return !(this.platform.is('cordova') || this.platform.is('capacitor'));
    return !this.platform.is('android') || !this.platform.is('ios');
  }
  getLoginBody(username: string, password: string) {
    const body = new URLSearchParams();
    body.set('client_id', Consts.kcClient);
    // body.set('client_secret', Consts.kcSecret);
    body.set('username', username);
    body.set('password', password);
    body.set('scope', 'openid offline_access');
    body.set('grant_type', 'password');
    return body;
  }


  getRefreshBody(refresh: string) {
    const body = new URLSearchParams();
    body.set('client_id', Consts.kcClient);
    body.set('client_secret',  Consts.kcSecret);
    body.set('scope', 'openid');
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refresh);
    return body;
  }

  isMaggioreOggi(date) {
    var evento = new Date(date).getTime().toString().substring(0, 10)
    var today = new Date().getTime().toString().substring(0, 10)
    if (evento < today) {
      return false
    } else {
      return true
    }
  }

  numberEvents(items: any) {
    var number = 0
    var today = new Date().getTime().toString().substring(0, 10)
    items.forEach((item: any) => {
      var evento = new Date(item.data_fine).getTime().toString().substring(0, 10)
      if(evento > today) {
        number += 1
      }
    })

    return number
  }


  isHttp(string: string) {
    if(string.includes('http://') || string.includes('https://')) {
      //
    } else {
      return 'http://'
    }
  }

  login(username: string, password: string): Observable<any > {
    const options = {
      'headers' :{'Content-Type': 'application/x-www-form-urlencoded'}
    };
    const data = this.getLoginBody(username, password);
    return this.http.post<any>(Consts.kcUrl, data, options);
  }

  logout(token: string, refresh: string){
    const body = new URLSearchParams();
    body.set('client_id', Consts.kcClient);
    body.set('client_secret',  Consts.kcSecret);
    body.set('refresh_token', refresh);
    const options = {
      'headers' :{
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer '+token}
    };
    return this.http.post<any>(Consts.kcUrlLogout, body, options);

  }

  refresh(refresh: string){
    const options = {
      'headers' :{'Content-Type': 'application/x-www-form-urlencoded'}
    };
    const data = this.getRefreshBody(refresh);
    return this.http.post<any>(Consts.kcUrl, data, options);
  }

  me(accesstoken: string): Observable<{ id: number; role: number; nome: string; avatar: string}>{
    return this.http.post<{ id: number; role: number; nome: string; avatar: string}>(this.baseUrl + '/me', {sessionid:accesstoken});
  }

  avatar(accesstoken: string, avatar: string): Observable<{ id: number}>{
    return this.http.post<{ id: number}>(this.baseUrl + '/avatar', {sessionid:accesstoken, avatar});
  }

  // me(sessionid: string, userid: string): Observable<{ id: number, role: number, nome: string, avatar: string}>{
  //   return this.http.post<{ id: number, role:number, nome: string, avatar: string}>(this.baseUrl + '/me',
  //     {sessionid:sessionid, userid:userid});
  // }

  getPreferiti(sessionid: string, userid: string){
    return this.http.post<Array<Preferito>>(this.baseUrl + '/getpreferiti',
      {sessionid:sessionid, userid:userid,});
  }

  isPreferito(sessionid: string, userid: string, itemid: number, tipo: number){
    return this.http.post<{res: boolean}>(this.baseUrl + '/ispreferito',
      {sessionid:sessionid, userid:userid, itemid: itemid, tipo:tipo});
  }

  setPreferito(sessionid: string, userid: string, itemid: number, tipo: number){
    return this.http.post<{res: boolean}>(this.baseUrl + '/setpreferito',
      {sessionid:sessionid, userid:userid, itemid: itemid, tipo:tipo});
  }

  // registrazione(userid: string, emailReg: string, nomeReg: string): Observable<{ success: boolean, result: number}>{
  //   return this.http.post<{ success: boolean, result: number}>(this.baseUrl + '/registrazione_utente',
  //     {userid: userid, emailReg: emailReg, nomeReg: nomeReg});
  // }

  // me(sessionid: string, userid: string): Observable<{ id: number, role: number, nome: string, avatar: string}>{
  //   return this.http.post<{ id: number, role:number, nome: string, avatar: string}>(this.baseUrl + '/me',
  //     {sessionid:sessionid, userid:userid});
  // }

  // getProdottiHome(): Observable<{vini: Array<Prodotto>, oli: Array<Prodotto>}>{
  //   return this.http.get<{vini: Array<Prodotto>, oli: Array<Prodotto>}>(this.baseUrl + '/prodottohome')
  // }
  getProdottiHome(): Observable<{vini: Array<Prodotto>, oli: Array<Prodotto>}> {
    return this.http.get<{vini: Array<Prodotto>, oli: Array<Prodotto>}>(this.baseUrl + '/prodottohome')
    // if(this.isDesktop()){
    //   return this.http.get<{vini: Array<Prodotto>, oli: Array<Prodotto>}>(this.baseUrl + '/prodottohome')
    // }
    // return new Observable<{vini: Array<Prodotto>, oli: Array<Prodotto>}>(observer => {
    //
    //   this.database.executeQuery<any>(async (db: SQLiteDBConnection) => {
    //     const products: DBSQLiteValues = await db.query("select * from prodotto where tipo = 1 ORDER BY RANDOM() LIMIT 6");
    //     const products2: DBSQLiteValues = await db.query("select * from prodotto where tipo = 2 ORDER BY RANDOM() LIMIT 6");
    //     const ret = {
    //       vini:products.values as Prodotto[],
    //       oli:products2.values as Prodotto[],
    //     };
    //     return ret;
    //   }).then(value => observer.next(value));
    // });

  }

  getProduttoriHome(): Observable<{cantine: Array<Produttore>; frantoi: Array<Produttore>}>{
    return this.http.get<{cantine: Array<Produttore>, frantoi: Array<Produttore>}>(this.baseUrl + '/produttorehome')

    // if(this.isDesktop()){
    //   return this.http.get<{cantine: Array<Produttore>; frantoi: Array<Produttore>}>(this.baseUrl + '/produttorehome');
    // }
    //
    // return new Observable<{cantine: Array<Produttore>; frantoi: Array<Produttore>}>(observer => {
    //
    //   this.database.executeQuery<any>(async (db: SQLiteDBConnection) => {
    //     const products: DBSQLiteValues = await db.query("select * from produttore where tipo in (1,3) ORDER BY RANDOM() LIMIT 6");
    //     const products2: DBSQLiteValues = await db.query("select * from produttore where tipo in (2,3) ORDER BY RANDOM() LIMIT 6");
    //     const ret = {
    //       cantine:products.values as Produttore[],
    //       frantoi:products2.values as Produttore[],
    //     };
    //     return ret;
    //   }).then(value => observer.next(value));
    // });
  }

  getProduttori(): Observable<Array<Produttore>>{
    return this.http.get<Array<Produttore>>(this.baseUrl + '/produttore')
  }
  getProduttoriVino(): Observable<Array<Produttore>>{
    return this.http.get<Array<Produttore>>(this.baseUrl + '/produttore_vino')
  }
  getProduttoriOlio(): Observable<Array<Produttore>>{
    return this.http.get<Array<Produttore>>(this.baseUrl + '/produttore_olio')
  }

  getProduttore(id: string): Observable<Produttore>{
    // return this.http.get<Produttore>(this.baseUrl + '/produttore/'+id)

    if(this.isDesktop()){
      return this.http.get<Produttore>(this.baseUrl + '/produttore/'+id);
    }
    return new Observable<Produttore>(observer => {

      this.database.executeQuery<any>(async (db: SQLiteDBConnection) => {
        const item: DBSQLiteValues = await db.query("select * from produttore where id ="+id);
        const out = item.values[0] as Produttore;
        let res = await db.query("select * from produttore_gallery where item ="+id);
        out.images = res.values as Image[];
        //prodotti
        res = await db.query('select * from prodotto where produttore = '+id);
        out.prodotti  = res.values as Prodotto[];
        //premi
        if(out.premi != null && out.premi.length>0 ){
          try {
            const q = 'select * from premi where id  in ' +
              out.premi.replace('[', '(').replace(']', ')');
            res = await db.query(q);
            out.premi2 = res.values as Premio[];
          } catch (error) {
            // throw Error(`DatabaseServiceError: ${error}`);
          }
        }

        const res3 = await db.query("select * from articolo where produttore ="+id);
        out.articolo = res3.values as Articolo[];

        return out;
      }).then(value => observer.next(value));
    });
  }

  getProdotto(id: string): Observable<Prodotto>{
    // return this.http.get<Prodotto>(this.baseUrl + '/prodotto/'+id)
    if(this.isDesktop()){
      return this.http.get<Prodotto>(this.baseUrl + '/prodotto/'+id)
    }
    return new Observable<Prodotto>(observer => {

      this.database.executeQuery<any>(async (db: SQLiteDBConnection) => {
        const item: DBSQLiteValues = await db.query("select * from prodotto where id ="+id);

        const out = item.values[0] as Prodotto;

        out.attributi = [];
        out.attributiEvidenza = [];
        out.images = [];
        out.abbinamenti = [];
        out.articoli = [];
        // out.categorie = [];
        out.prodottiConsigliati = [];
        out.idProduttore = 0;

        //attributi
        let res: DBSQLiteValues = await db.query("select * from prodotto_attributi where prodotto ="+id);
        const res1 = res.values as Attributo[];
        out.attributi = res1;
        out.attributi .forEach(value => {
          if(value.top === 1){
            out.attributiEvidenza.push(value);
          }
        });

        //gallery
        res = await db.query("select * from prodotto_gallery where item ="+id);
        out.images = res.values as Image[];

        //abbinamenti
        // eslint-disable-next-line max-len
        res = await db.query('select * from abbinamento where id in (SELECT abbinamento from prodotto_abbinamenti where prodotto = '+id+')');
        out.abbinamenti = res.values as Abbinamento[];

        //articoli
        // eslint-disable-next-line max-len
        res = await db.query('select * from articolo where id in (SELECT articolo from prodotto_articoli where prodotto = '+id+') order by titolo');
        out.articoli = res.values as Articolo[];

        // prodottiConsigliati
        res = await db.query('select * from prodotto where id != '+id+' AND produttore = '+out.produttore);
        out.prodottiConsigliati = res.values as Prodotto[];

        // Produttore
        res = await db.query('select * from produttore where id = '+out.produttore);
        const res2 = res.values[0] as Produttore;
        out.idProduttore = res2.id;
        out.nomeProduttore = res2.nome;
        out.excerptProduttore = res2.excerpt;
        out.sottotitoloProduttore = res2.sottotitolo;
        out.sottotitoloProduttore_en = res2.sottotitolo_en;
        out.sottotitoloProduttore_de = res2.sottotitolo_de;
        out.imgProduttore = res2.logo;
        return out;
      }).then(value => observer.next(value));
    });
  }

  getProdotti(tipo: number, categorie: Array<number>, produttori: Array<number>, page: number):
    Observable<{items: Array<Prodotto>, produttori: Array<Produttore>, categprie: Array<number>, hasNext:boolean}>{
    return this.http.post<{items: Array<Prodotto>, produttori: Array<Produttore>, categprie:Array<number>, hasNext:boolean }>
    (this.baseUrl + '/prodotti/filtra', {tipo:tipo, page:page, categorie: categorie, produttori: produttori});
  }

  getPois(): Observable<Array<Poi>>{
    return this.http.get<Array<Poi>>(this.baseUrl + '/pois')
  }

  getPoi(id: number): Observable<Poi>{
    // return this.http.get<Articolo>(this.baseUrl + '/articolo/'+id)
    if(this.isDesktop()){
      return this.http.get<Poi>(this.baseUrl + '/pois/'+id);
    }
    else{
      return new Observable<Poi>(observer => {

        this.database.executeQuery<any>(async (db: SQLiteDBConnection) => {
          const item: DBSQLiteValues = await db.query("select * from poi where id ="+id);
          const out = item.values[0] as Poi;
          return out;
        }).then(value => observer.next(value));
      });
    }
  }

  getCategorieVino(): Observable<Array<Categoria>>{
    return this.http.get<Array<Categoria>>(this.baseUrl + '/categoria_vino')
  }

  getCategorieOlio(): Observable<Array<Categoria>>{
    return this.http.get<Array<Categoria>>(this.baseUrl + '/categoria_olio')
  }

  getProposte(): Observable<Array<Proposta>>{
    return this.http.get<Array<Proposta>>(this.baseUrl + '/proposta')
  }

  getArticoli(): Observable<Array<Articolo>>{
    return this.http.get<Array<Articolo>>(this.baseUrl + '/articolo_pubblicati_rand');
  }
  getArticoliFiltro(categorie: Array<number>): Observable<Array<Articolo>>{
    return this.http.post<Array<Articolo>>(this.baseUrl + '/articolo_pubblicati',{categorie: categorie});
  }
  getProposteFiltro(type: number): Observable<Array<Articolo>>{
    return this.http.get<Array<Articolo>>(this.baseUrl + '/proposte_pubblicati/'+type);
  }


  getEventi(datestart: string, dateend: string, categorie: Array<number>): Observable<Array<Articolo>>{
    return this.http.post<Array<Articolo>>(this.baseUrl + '/eventi_pubblicati',
      {datestart: datestart, dateend: dateend, categorie: categorie});
  }
  getArticolo(id: string): Observable<Articolo>{
    // return this.http.get<Articolo>(this.baseUrl + '/articolo/'+id)
    if(this.isDesktop()){
      return this.http.get<Articolo>(this.baseUrl + '/articolo/'+id)
    }
    else{
      return new Observable<Articolo>(observer => {

        this.database.executeQuery<any>(async (db: SQLiteDBConnection) => {
          const item: DBSQLiteValues = await db.query("select * from articolo where id ="+id);
          const out = item.values[0] as Articolo;
          const res = await db.query("select * from articolo_gallery where articolo ="+id);
          out.images = res.values as Image[];
          out.punti =[];
          // console.log("punti:"+out.punti2);
          if(out.punti2 !== null && out.punti2.length > 0){
            out.punti =  JSON.parse(out.punti2);
          }
          out.pois =[];
          if(out.pois2 !== null && out.pois2.length > 0){
            out.pois3 =  JSON.parse(out.pois2);
          }

          const allPoisQuery: DBSQLiteValues = await db.query("select * from poi");
          const allPois = allPoisQuery.values as Poi[];
          out.pois3.forEach(value => {
            allPois.forEach(value1 => {
              if(value === value1.id){
                out.pois.push(value1);
              }
            });
          });
          return out;
        }).then(value => observer.next(value));
      });
    }
  }

  getCategorieArticolo(tipo:number): Observable<Array<Categoria>>{
    return this.http.get<Array<Categoria>>(this.baseUrl + '/categoria_articolo_get/'+tipo);
  }
  pubblica( titolo: string, testo: string, imgs: Array<string>, localities: Array<{lat: number; lng: number}>): Observable<{ success: boolean }>{
    return this.http.post<{ success: boolean }>(this.baseUrl + '/pubblica',
      {titolo: titolo, testo: testo, imgs: imgs, localities: localities});
  }

  getPubblicati(): Observable<Array<Post>>{
    return this.http.get<Array<Post>>(this.baseUrl + '/getPubblicati');
  }

  getPost(id: number): Observable<Post>{
    return this.http.get<Post>(this.baseUrl + '/post/'+id);
  }
  // getServizi(type: number): Observable<Array<Servizio>>{
  //   return this.http.get<Array<Servizio>>(this.baseUrl + '/servizio_pubblicati');
  //   // if(this.isDesktop()) {
  //   //   return this.http.get<Array<Servizio>>(this.baseUrl + '/servizio_pubblicati');
  //   // }
  //   // return new Observable<Array<Servizio>>(observer => {
  //   //
  //   //   this.database.executeQuery<any>(async (db: SQLiteDBConnection) => {
  //   //     const item: DBSQLiteValues = await db.query("select * from servizio ");
  //   //     const out = item.values as Servizio[];
  //   //     return out;
  //   //   }).then(value => observer.next(value));
  //   // });
  // }
  getServizio(id: string): Observable<Servizio> {
    // return this.http.get<Servizio>(this.baseUrl + '/servizio/' + id);
    if(this.isDesktop()){
      return this.http.get<Servizio>(this.baseUrl + '/servizio/' + id);
    }
    else{
      return new Observable<Servizio>(observer => {

        console.log('select * from servizio where id ='+id);
        this.database.executeQuery<any>(async (db: SQLiteDBConnection) => {
          const item: DBSQLiteValues = await db.query('select * from servizio where id ='+id);
          const out = item.values[0] as Servizio;
          const res = await db.query('select * from servizio_gallery where servizio ='+id);
          out.images = res.values as Image[];
          if(out.articolo2 != null && out.articolo2 !== '') {
            // const  ar = JSON.parse(out.articolo2);
            const  ar =out.articolo2.replace('[', '(').replace(']', ')');
            out.articolo = Array<Articolo>();
            const res1 = await db.query('select * from articolo where id  IN  '+ar);
            out.articolo = res1.values as Articolo[];

            const  pr =out.produttori2.replace('[', '(').replace(']', ')');
            out.produttori = Array<Produttore>();
            const res2 = await db.query('select * from produttore where id  IN  '+pr);
            out.produttori = res2.values as Produttore[];
          }
          return out;
        }).then(value => observer.next(value));
      });
    }
  }
  getAddress(address: string): Observable<any>{
    const indirizzo = address.replace(' ', '+');
    return this.http.get<any>(this.baseUrl + '/address/'+address);
  }
  ricerca(valore: string, lang: string):
    Observable<{ prodotti: Array<Preferito>; produttori: Array<Preferito>; articoli: Array<Preferito> }> {

    if(this.isDesktop()){
      return this.http.post<{prodotti: Array<Preferito>; produttori: Array<Preferito>; articoli: Array<Preferito> }>
      (this.baseUrl + '/ricerca', {valore, lang});
    }
    // return this.http.post<{prodotti: Array<Preferito>; produttori: Array<Preferito>; articoli: Array<Preferito> }>
    // (this.baseUrl + '/ricerca', {valore, lang});
      // "select * from `prodotto` where `nome` like ? order by `nome` asc limit 12",
      // "select * from `produttore` where `nome` like ? order by `nome` asc limit 12",
      // "select * from `articolo` where `titolo` like ? and `status` = ? order by `id` desc limit 12"
    return new Observable<{prodotti: Array<Preferito>; produttori: Array<Preferito>; articoli: Array<Preferito> }>(observer => {
      let lingua = '_'+lang;
      if(lingua === '_it'){lingua = '';}
      const field1 = 'nome'+lingua;
      const field2 = 'titolo'+lingua;
      let q1 = '';
      let q2 = '';
      let q3 = '';
      if(valore && valore.length > 0){
        valore = valore.replace('"', '');
        q1 = 'where '+field1+' like "%'+valore+'%" and bozza != 1';
        q2 = 'where nome like "%'+valore+'%" and bozza != 1';
        q3 = 'where '+field2+' like "%'+valore+'%" and bozza != 1';
      }
      this.database.executeQuery<any>(async (db: SQLiteDBConnection) => {
        const prodotto: DBSQLiteValues = await db.query('select * from `prodotto` '+q1+'  order by '+field1+' asc limit 12');
        const produttore: DBSQLiteValues = await db.query('select * from `produttore` '+q2+'  order by nome asc limit 12');
        const articolo: DBSQLiteValues = await db.query('select * from `articolo` '+q3+'  order by `id` desc limit 12');

        const i1 =prodotto.values as Prodotto[];
        const i2 =produttore.values as Produttore[];
        const i3 =articolo.values as Articolo[];

        const o1: Array<Preferito> = [];
        const o2: Array<Preferito> = [];
        const o3: Array<Preferito> = [];
        i1.forEach(value => {
          const item = {} as Preferito;
          item.id = value.id;
          item.itemid = value.id+'';
          item.tipo = 1;
          item.img = '/prodotti/'+value.img;
          item.titolo = value.nome;
          item.titolo_en = value.nome_en;
          item.titolo_de = value.nome_de;
          item.sottotitolo = value.sottotitolo;
          item.sottotitolo_en = value.sottotitolo_en;
          item.sottotitolo_de = value.sottotitolo_de;
          o1.push(item);
        });

        i2.forEach(value => {
          const item = {} as Preferito;
          item.id = value.id;
          item.itemid = value.id+'';
          item.tipo = 2;
          item.img = '/produttore_logo/'+value.logo;
          item.titolo = value.nome;
          item.titolo_en = value.nome;
          item.titolo_de = value.nome;
          item.sottotitolo = value.sottotitolo;
          item.sottotitolo_en = value.sottotitolo_en;
          item.sottotitolo_de = value.sottotitolo_de;
          o2.push(item);
        });

        i3.forEach(value => {
          let tipo = 3; // articoli
          if(value.tipo === 2) { // eventi
            tipo = 5;
          }
          if(value.tipo === 3) { // proposte
            tipo = 4;
          }
          const item = {} as Preferito;
          item.id = value.id;
          item.itemid = value.id+'';
          item.tipo = tipo;
          item.img = '/articoli/'+value.img;
          item.titolo = value.titolo;
          item.titolo_en = value.titolo_en;
          item.titolo_de = value.titolo_de;
          item.sottotitolo = value.sottotitolo;
          item.sottotitolo_en = value.sottotitolo_en;
          item.sottotitolo_de = value.sottotitolo_de;
          o3.push(item);
        });

        const ret = {prodotti:o1, produttori:o2, articoli:o3};
        return ret;
      }).then(value => observer.next(value));
    });
  }
  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/dump/getAll');
  }

  getCategorieServizio(tipo: number): Observable<Array<Categoria>>{
    return this.http.get<Array<Categoria>>(this.baseUrl + '/categoria_servizio_get/'+tipo);
  }

  getServiziFiltro(tipo: number, categorie: Array<number>): Observable<Array<Servizio>>{
    return this.http.post<Array<Servizio>>(this.baseUrl + '/servizi_pubblicati',{tipo, categorie});
  }

}
