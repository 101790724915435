import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() background: string = "#fff"
  // @Input() textAlign: string = "start"
  @Input() boxshadow: string = ""
  @Input() height: string = "100px"

  @Input() isCover: boolean = false;
  @Input() isHome: boolean = false;

  @Input() type: string = ''; //tipo di contenuto
  @Input() image: string = '';
  @Input() titolo: string = '';
  @Input() descrizione: string = '';
  @Input() isCenter = false;

  constructor() { }

  ngOnInit() {

    if(this.image == null || this.image === '') {
      if (this.type === 'vini' ) {
        this.image = 'assets/wineMockup.jpg';
      } else if (this.type === 'oli' ) {
        this.image = 'assets/oilMockup.jpg';
      } else  {
        this.image = 'assets/images/profile2.svg';
      }
    }
    if(this.type === 'vini' ||this.type === 'oli' ||this.type === 'produttore') {
      if (this.descrizione.length > 42) {
        this.descrizione = this.descrizione.substring(0, 40) + "...";
      }
    }
  }

}
// 132-100.jpg
