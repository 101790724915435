import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {CardComponent} from '../components/card/card.component';
import {SmallCardComponent} from '../components/small-card/small-card.component';
// import {FiltroComponent} from "../components/filtro/filtro.component";
import {IonicModule} from '@ionic/angular';
import {HeaderComponent} from '../components/header/header.component';
// import {TabsPage} from "../tabs/tabs.page";
import {CantinaMapsComponent} from '../components/cantina-maps/cantina-maps.component';
import {SwiperModule} from 'swiper/angular';
import {MapComponent} from '../components/map/map.component';
import {SideMenuComponent} from '../components/side-menu/side-menu.component';
import {TranslateModule} from '@ngx-translate/core';
import {LoginAlertModalComponent} from "../components/login-alert-modal/login-alert-modal.component";
import {PostSuccessModalComponent} from "../components/post-success-modal/post-success-modal.component";
import {MapModalPoiComponent} from "../components/map-modal-poi/map-modal-poi.component";
import {CachedImageComponent} from "../components/cached-image/cached-image.component";


@NgModule({
  declarations: [CardComponent, SmallCardComponent, /*FiltroComponent,*/ HeaderComponent,
    LoginAlertModalComponent, PostSuccessModalComponent, MapModalPoiComponent,
    CantinaMapsComponent, MapComponent, SideMenuComponent, CachedImageComponent],
  imports: [
    CommonModule,
    IonicModule,
    SwiperModule,
    TranslateModule
  ],
  exports: [CardComponent, SmallCardComponent, /*FiltroComponent,*/ HeaderComponent, CantinaMapsComponent,
    MapComponent, SideMenuComponent, CachedImageComponent],
})
export class SharedModule { }
