import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {SharedModule} from './shared/shared.module';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {MapModalComponent} from './components/map-modal/map-modal.component';
import {SwiperModule} from 'swiper/angular';
import {ImageZoomComponent} from './components/image-zoom/image-zoom.component';
import {LightboxModule} from 'ngx-lightbox';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TokenInterceptor} from './interceptor';
import {InitializeAppService} from './initialize.app.service';
import {DatabaseService} from './database.service';
import {SQLiteService} from './sqlite.service';
import {CapImageCacheModule} from 'cap-image-cache';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function initializeFactory(init: InitializeAppService) {
  return () => init.initializeApp();
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent,MapModalComponent, ImageZoomComponent],
  imports: [BrowserModule, IonicModule.forRoot(
    { rippleEffect: false,
    mode: 'md'}),
    AppRoutingModule, SharedModule, HttpClientModule,SwiperModule,
    // IonicImageLoader.forRoot(),
    LightboxModule, HttpClientJsonpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  CapImageCacheModule.forRoot({cachePath: 'CACHE_IMAGES'})
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    InitializeAppService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFactory,
      deps: [InitializeAppService],
      multi: true
    },
    DatabaseService,
    SQLiteService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
