import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Directory, Filesystem} from '@capacitor/filesystem';

const CACHE_FOLDER = 'CACHE-IMG'


@Component({
  selector: 'app-cached-image',
  templateUrl: './cached-image.component.html',
  styleUrls: ['./cached-image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CachedImageComponent implements OnInit {

  _src = '';
  @Input() spinner = false;

  @Input()
  set src(imageUrl: string) {
    console.log('SET SOURCE:', imageUrl);
    const imageName = imageUrl.split('/').pop();
    const fileType = imageName.split('.').pop();

    Filesystem.readFile({
      directory: Directory.Cache,
      path: `${CACHE_FOLDER}/${imageName}`
    }).then(res => {
      this._src = `data:image/${fileType};base64,${res.data}`;
    }).catch(e => {
      console.log(e);
      this.savePicture(imageUrl, imageName).then(r => {
        Filesystem.readFile({
          directory: Directory.Cache,
          path: `${CACHE_FOLDER}/${imageName}`
        }).then(res => {
          this._src = `data:image/${fileType};base64,${res.data}`;
        }).catch(e => {
          console.log(e);
        });
      });
    });

  }

  private async savePicture(url, path) {
    // Convert photo to base64 format, required by Filesystem API to save
    const base64Data = await this.readAsBase64(url, path);

    // Write the file to the data directory
    // Use webPath to display the new image instead of base64 since it's
    // already loaded into memory
    return await Filesystem.writeFile({
      path: `${CACHE_FOLDER}/${path}`,
      data: base64Data,
      directory: Directory.Cache
    });
  }

  private async readAsBase64(url, path) {
    // Fetch the photo, read as a blob, then convert to base64 format
    const response = await fetch(url);
    const blob = await response.blob();

    return await this.convertBlobToBase64(blob) as string;
  }

  private convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  constructor() {
  }

  ngOnInit() {
  }

}
