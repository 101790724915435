import {Component, Input, OnInit} from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";

@Component({
  selector: 'app-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss'],
})
export class ImageZoomComponent implements OnInit {
  @Input() image: string = "assets/images/vino1.png"


  img:string

  constructor(public modalCtrl: ModalController, public navParams: NavParams) {
    this.img = this.navParams.get('imgurl');
  }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
