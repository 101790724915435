import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import {Produttore} from "../../model/produttore";
import {ApiService} from "../../api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Consts} from "../../conts";

@Component({
  selector: 'app-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.scss'],
})
export class MapModalComponent implements OnInit {
  id: string;
  imgProduttore = Consts.dirLogoProduttore;
  item: Produttore = <Produttore>{};
  constructor(public modalCtrl: ModalController, public navParams: NavParams, public api: ApiService, private route: ActivatedRoute,
              private router: Router) {
    this.id = this.navParams.get('id');
  }

  ngOnInit() {
    this.api.getProduttore(this.id).subscribe(res => {
      this.item = res;
    });
  }

  toProduttore(id: number){
    this.modalCtrl.dismiss();
    this.router.navigate(['cantina/'+id]);
  }

}
