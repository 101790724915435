import {Component, NgZone} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Consts} from "./conts";
import {TranslateService} from '@ngx-translate/core';
import {Platform} from "@ionic/angular";
import OneSignal from 'onesignal-cordova-plugin';
import {App, URLOpenListenerEvent} from "@capacitor/app";
import {Router} from "@angular/router";
import {ApiService} from "./api.service";
import {DatabaseService} from "./database.service";
import {KcService} from "./kc.service";
import {StatusBar} from "@capacitor/status-bar";
import {Directory, Filesystem} from "@capacitor/filesystem";

declare let google: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  prefDark = window.matchMedia('(prefers-color-scheme: dark)');

  async createCacheFolder() {
    await Filesystem.mkdir({
      directory: Directory.Cache,
      path: 'CACHE-IMG'
    })
  }

  constructor(private http: HttpClient,
              private databaseService: DatabaseService,
              private translate: TranslateService,
              private platform: Platform,
              private router: Router,
              private api: ApiService,
              private kc: KcService,
              private zone: NgZone) {
    this.platform.ready().then(() => {
      this.createCacheFolder()
      const darkmode = localStorage.getItem(Consts.darkmode);
      if (darkmode == null || darkmode === '') {
        if (this.prefDark.matches) {
          localStorage.setItem(Consts.darkmode, 'dark');
        } else {
          localStorage.setItem(Consts.darkmode, 'light');
        }
      } else {
        document.body.classList.toggle('dark', darkmode === 'dark');
      }
      StatusBar.setBackgroundColor({
        color: '#A1324B'
      });
      // document.body.setAttribute('data-theme', 'light');
      // console.log(this.prefDark);
      this.initializeApp();
      this.initTranslate();
      if (!this.isDesktop()) {
        this.oneSignalInit();
      }
      const refresh = localStorage.getItem(Consts.refreshokenVeronese);
      if (refresh != null && refresh.length > 0) {
        this.api.refresh(refresh).subscribe(res => {

          localStorage.setItem(Consts.tokenVeronese, res.access_token);
          // localStorage.setItem(Consts.refreshokenVeronese, res.refresh_token)
          // console.log('app.component refresh token' + res.access_token);
        });
      }


    });
    this.http.jsonp('https://maps.googleapis.com/maps/api/js?key=' + Consts.apiKey + '&libraries=drawing,geometry', 'callback')
      .subscribe(res => {
      });
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split('.com').pop();
        // console.log(slug);
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }

  isDesktop(): boolean {
    return !(this.platform.is('cordova') || this.platform.is('capacitor'));
    return !this.platform.is('android') || !this.platform.is('ios');
  }

  private oneSignalInit(): void {
    // OneSignal.setLogLevel(6, 0);
    OneSignal.setAppId(Consts.oneSignalKey);
    OneSignal.setNotificationOpenedHandler(jsonData => {
      // console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });
    OneSignal.promptForPushNotificationsWithUserResponse(accepted => {
      // console.log('User accepted notifications: ' + accepted);
    });

    const tag1 = localStorage.getItem(Consts.tagStatusProdotti);
    const tag2 = localStorage.getItem(Consts.tagStatusProposte);
    const tag3 = localStorage.getItem(Consts.tagStatusEventi);
    if (tag1 === null) {
      this.sendTag("new_prodotti", "1");
    }
    if (tag2 === null) {
      this.sendTag("new_proposte", "1");
    }
    if (tag3 === null) {
      this.sendTag("new_eventi", "1");
    }
  }

  private sendTag(key, val) {
    if (!this.isDesktop()) {
      OneSignal.getTags(tags => {
        // console.log(tags);
      });
      OneSignal.sendTag(key, val);
    }
  }

  private initTranslate() {
    this.translate.setDefaultLang('en');

    const lang = localStorage.getItem(Consts.langVeronese);

    if (lang !== null) {
      this.translate.use(lang);
    } else if (this.translate.getBrowserLang() !== undefined) {
      this.translate.use(this.translate.getBrowserLang());
      localStorage.setItem(Consts.langVeronese, this.translate.getBrowserLang());
    } else {
      this.translate.use('en');
      localStorage.setItem(Consts.langVeronese, 'en');
    }
  }
}
