export class Consts {
  public static apiKey = 'AIzaSyBxXqgHzStuy4RyyFEnqGuIgoOlRv78ELA';
  public static oneSignalKey = '886b9878-9174-43d3-b9af-92739f6c076a';

  // public static baseUrl = 'https://api.veronesedoc.it/';
  // public static kcUrl = 'https://kc.veronesedoc.it/realms/Veronese/protocol/openid-connect/token';
  // public static kcUrlLogout = 'https://kc.veronesedoc.it/realms/Veronese/protocol/openid-connect/logout';
  // public static kcBaseUrl = 'https://kc.veronesedoc.it/';
  // public static appBaseUrl = 'https://app.veronesedoc.it/';

  public static baseUrl = 'https://api.veronappeal.com/';
  public static kcUrl = 'https://kc.veronappeal.com/realms/Veronese/protocol/openid-connect/token';
  public static kcUrlLogout = 'https://kc.veronappeal.com/realms/Veronese/protocol/openid-connect/logout';
  public static kcBaseUrl = 'https://kc.veronappeal.com/';
  public static appBaseUrl = 'https://app.veronappeal.com/';
  // public static appBaseUrl = 'https://app.veronesedoc.it/';

  // public static redirectWeb = 'http://localhost:8100/account';
  // public static redirectWeb = 'https://app.veronesedoc.it/loginsocial';
  // public static redirectMobile = 'veronese://app.veronesedoc.it/account';
  // public static redirectWeb = 'http://localhost:8100/tabs/home';


  // public static redirectWeb = 'https://app.veronesedoc.it/tabs/home';
  // public static redirectMobile = 'veronese://app.veronesedoc.it/tabs/home';

  public static redirectWeb = 'https://app.veronappeal.com/tabs/home';
  public static redirectMobile = 'veronese://app.veronappeal.com/tabs/home';

  // public static redirectWebReg = 'https://app.veronesedoc.it/welcome';
  // public static redirectMobileReg = 'veronese://app.veronesedoc.it/welcome';
  public static redirectWebReg = 'https://app.veronappeal.com/welcome';
  public static redirectMobileReg = 'veronese://app.veronappeal.com/welcome';
  public static kcClient = 'veronese';
  public static kcSecret = 'JePpNsxZLpMpar07bEwJaTKVoiJCmBwl';

  // base url images
  public static imgPremi = this.baseUrl+"img/premi/";
  public static dirLogoProduttore = this.baseUrl+"img/produttore_logo/";
  public static dirLogoProdotto = this.baseUrl+"img/prodotti/";
  public static dirLogoAbbinamento = this.baseUrl+"img/abbinamento_logo/";
  public static dirLogoCategoria = this.baseUrl+"img/categoria/"
  public static dirLogoArticoli = this.baseUrl+"img/articoli/"
  public static dirLogoPost = this.baseUrl+"img/post/"
  public static dirLogoServizi = this.baseUrl+"img/servizio/"
  public static dirLogoPoi = this.baseUrl+"img/poi/"
  public static icons = this.baseUrl

// key local storage
  public static tokenVeronese = "tokenVeronese"
  public static refreshokenVeronese = "refreshokenVeronese"
  public static darkmode = "darkmode"
  public static useridVeronese = "useridVeronese"
  public static user = "userVeronese"
  public static role = "roleVeronese"
  public static langVeronese = "langVeronese"
  public static darkVeronese = "darkVeronese"

  public static tagStatusProdotti = "tagStatusProdotti"
  public static tagStatusProposte = "tagStatusProposte"
  public static tagStatusEventi = "tagStatusEventi"


  public static queryMapPois = "queryMapPois"
  public static queryMapSingle = "queryMapSingle"
  public static defaultLat = 45.439755453695874;
  public static defaultLng = 10.988120791223496;

  //types
  public static produttoreTypeVino = 1;
  public static produttoreTypeOlio = 2;

  public static tipoProdotto = 1;
  public static tipoProduttore = 2;
  public static tipoArticolo = 3;
  public static tipoProposta = 4;
  public static tipoEvento = 5;


  public static stile: google.maps.MapTypeStyle[] = [
    {
      "featureType": "all",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
        {
          "color": "#e5e8e7"
        },
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#f5f5f2"
        },
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.attraction",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.business",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.government",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.medical",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "all",
      "stylers": [
        {
          "color": "#91b65d"
        },
        {
          "gamma": 1.51
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.place_of_worship",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.school",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.sports_complex",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.sports_complex",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c7c7c7"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "all",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.icon",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "simplified"
        },
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "all",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
        {
          "color": "#a0d3d3"
        }
      ]
    }
  ];

}
