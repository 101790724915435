import {EventEmitter, Injectable, Output} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MapPickerDataService {
  static puntiSelected: Array<{lat: number; lng: number}> = [];
  onConfirm: Subject<boolean> = new Subject<boolean>();
  color0 = '#CF2850';
  color1 = '#45b201';
  color2 = '#600720';
  color3 = '#cfcc28';
  color4 = '#28cfc1';
  color5 = '#cf28cc';
  color6 = '#f38c4b';
  constructor() { }


  confirm(){
    this.onConfirm.next(true);
  }

  addPunto(punto){
    MapPickerDataService.puntiSelected.push(punto);
    console.log(MapPickerDataService.puntiSelected);
    // this.addPoint.emit("punto");
  }

  getAll(){
    return MapPickerDataService.puntiSelected;
  }
  reset(){
    MapPickerDataService.puntiSelected = [];
  }

  svg(text: number) {
    const color = this.color0;
    return window.btoa(`
<svg id="Livello_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
<defs><style>.cls-1{fill:${color};}</style></defs>
<g id="Livello_1-2"><circle class="cls-1" cx="16" cy="16" r="16"/>
<text x="50%" y="55%" dominant-baseline="middle" text-anchor="middle" font-family="sans-serif" font-weight="700" font-size="16" fill="white">${text}</text>
</g></svg>`);
  }

  svg2() {
    return window.btoa(`
<svg id="Livello_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
<defs><style>.cls-1{fill:#cf2850;}</style></defs>
<g id="Livello_1-2"><circle class="cls-1" cx="16" cy="16" r="16"/>
</g></svg>`);
  }

  svg3(text: number, type: number) {
    const colors = [this.color0, this.color1, this.color2, this.color3, this.color4, this.color5, this.color6];

    if(type<0){type = 0;}
    const color = colors[type];
    return window.btoa(`
<svg id="Livello_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
<defs><style>.cls-1{fill:${color};}</style></defs>
<g id="Livello_1-2"><circle class="cls-1" cx="16" cy="16" r="16"/>
</g></svg>`);
  }

  svg4(color: string) {
    return window.btoa(`
<svg id="Livello_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
<defs><style>.cls-1{fill:${color};}</style></defs>
<g id="Livello_1-2"><circle class="cls-1" cx="16" cy="16" r="16"/>
</g></svg>`);
  }
}
