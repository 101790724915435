import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ModalController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-post-success-modal',
  templateUrl: './post-success-modal.component.html',
  styleUrls: ['./post-success-modal.component.scss'],
})
export class PostSuccessModalComponent implements OnInit {

  constructor(private router: Router,
              public modalCtrl: ModalController,
              private translate: TranslateService) { }

  ngOnInit() {}

  toHome(){
    this.modalCtrl.dismiss();
    this.router.navigate(['tabs/home']);
  }

}
