import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login-alert-modal',
  templateUrl: './login-alert-modal.component.html',
  styleUrls: ['./login-alert-modal.component.scss'],
})
export class LoginAlertModalComponent implements OnInit {

  constructor(private router: Router,
              public modalCtrl: ModalController,
              private translate: TranslateService) { }

  ngOnInit() {}


  closeModal(){
    this.modalCtrl.dismiss();
  }

  toLogin(){
    this.modalCtrl.dismiss();
    this.router.navigate(['account']);
  }
}
