import { Injectable } from '@angular/core';
import {SQLiteService} from './sqlite.service';
import {ApiService} from "./api.service";
import {Platform} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class InitializeAppService {

  constructor(private sqliteService: SQLiteService, private api: ApiService, private platform: Platform, ) { }
  async initializeApp() {
    if(this.isDesktop()) {
      return;
    }
    await this.sqliteService.initializePlugin().then(async (ret) => {
      try {
        //execute startup queries
        // await this.migrationService.migrate();
        console.log('init');

        let result: any = await this.sqliteService.echo('Hello World sqlite');
        console.log('init:'+ret+' '+result);

        this.api.getAll( ).subscribe(res => {
          console.log('getAll');
          console.log(res);

          this.load(JSON.stringify(res));
        });
      } catch (error) {
        throw Error(`initializeAppError: ${error}`);
      }

    });
  }
  isDesktop(): boolean {
    return !(this.platform.is('cordova') || this.platform.is('capacitor'));
    return !this.platform.is('android') || !this.platform.is('ios');
  }
  async load(data: string){
    // await this.sqliteService.closeConnection('veronesex');
    let result: any = await this.sqliteService.echo("Hello World1");
    result = await this.sqliteService.isJsonValid(data);
    if(!result.result) {
      console.log('not valid');
      return Promise.reject(new Error("IsJsonValid failed"));
    }
    console.log('json valid');

    result = await this.sqliteService.importFromJson(data);
    if(result.changes.changes === -1 ) {
      return Promise.reject(new Error("ImportFromJson 'full' dataToImport failed"));
    }

    // const db = await this.sqliteService
    //   .createConnection('veronesex', false,
    //     'no-encryption', 1);
    // if(db === null) {
    //   return Promise.reject(new Error("CreateConnection 'db-from-json' failed"));
    // }
    // await db.open();

    // result = await db.createSyncTable();
    // if (result.changes.changes < 0) {
    //   return Promise.reject(new Error("CreateSyncTable failed"));
    // }
    //
    //
    // result = await db.getSyncDate();
    // if(result.length === 0) {
    //   return Promise.reject(new Error("GetSyncDate failed"));
    // }

    // result = await db.query("SELECT * FROM categoria_articolo;");
    // console.log(`&&& Query4: ${result.values.length}`);
    // console.log(`&&& Query4: ${JSON.stringify(result)}`);
    // if(result.values.length !== 4 ||
    //   result.values[0].name !== "Whiteley" ||
    //   result.values[1].name !== "Jones" ||
    //   result.values[2].name !== "Simpson" ||
    //   result.values[3].name !== "Brown"  ) {
    //   return Promise.reject(new Error("Query 4 Users failed"));
    // }

    // close the connection
    // await this.sqliteService.closeConnection("veronesex");
  }
}
