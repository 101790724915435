import {AfterViewInit, Component, ElementRef, Input, NgModule, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../api.service';
import {Consts} from '../../conts';
import {MapPickerDataService} from '../../map-picker-data.service';
import {contrast} from "ionicons/icons";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})

export class MapComponent implements OnInit, AfterViewInit {
  map;
  @Input() height = '200px';
  @Input() query: string;
  @Input() fixed: boolean = false;
  @Input() empty: boolean = false;
  @Input() id: string;
  @Input() nome: string;
  @Input() lat: string;
  @Input() lng: string;
  @Input() select: string;
  @Input() loadProposta: number = 0;
  // @Input() punti: Array<{ lat: number;lng: number }>
  @Input() boxShadow = 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px';
  @ViewChild('mapRef') mapRef: ElementRef<HTMLElement>;
  bounds = null;
  constructor( private api: ApiService, private picker: MapPickerDataService) {
  }

  async ngAfterViewInit() {
    if(this.lat == null || this.lng == null){
      this.lat = ''+Consts.defaultLat;
      this.lng = ''+Consts.defaultLng;
    }
    this.bounds = new google.maps.LatLngBounds();
    const opt = {
      center: {lat: Number(this.lat), lng: Number(this.lng)},
      zoom: 8,
      disableDefaultUI: true,
      disableDoubleClickZoom: false,
      draggable: true,
      scrollwheel: true,
      panControl: true,
      styles:Consts.stile
    };
    if (this.fixed === true) {
      opt.disableDoubleClickZoom = true;
      opt.draggable = false;
      opt.scrollwheel = false;
      opt.panControl = false;
    }

    this.map = new google.maps.Map(
      document.getElementById('mappa2_'+this.id) as HTMLElement,opt
    );

    this.map.addListener('click', () => {
      const destination = Number(this.lat) + ',' + Number(this.lng);
      window.open("https://www.google.com.sa/maps/search/"+ destination );
    })

    if(!this.empty) {
      const marker = new google.maps.Marker({
        position: {lat: Number(this.lat), lng: Number(this.lng)},
        title: this.nome,
        icon: {
          url: `data:image/svg+xml;base64,${this.picker.svg2()}`,
          scaledSize: new google.maps.Size(16, 16),
          labelOrigin: new google.maps.Point(16, 16)
        },
      });

      marker.addListener('click', () => {
        const destination = Number(this.lat) + ',' + Number(this.lng);
        window.open("https://www.google.com.sa/maps/search/"+ destination );
      });
      marker.setMap(this.map);
    }
    if(this.loadProposta > 0){
      this.api.getArticolo(this.loadProposta+'').subscribe(res => {

        // console.log(res.punti);

        let index = 1;
        // res.punti.forEach(value => {
        //   const marker = new google.maps.Marker({
        //     position: {lat: Number(value.lat), lng: Number(value.lng)},
        //     icon: {
        //       url: `data:image/svg+xml;base64,${this.picker.svg(index)}`,
        //       scaledSize: new google.maps.Size(16, 16),
        //       labelOrigin: new google.maps.Point(16, 16)
        //     },
        //   });
        //   marker.setMap(this.map);
        //   index++;
        // });

        res.pois.forEach(value => {
          const marker = new google.maps.Marker({
            // title:value.nome,
            position: {lat: Number(value.lat), lng: Number(value.lng)},
            icon: {
              url: `data:image/svg+xml;base64,${this.picker.svg(index)}`,
              scaledSize: new google.maps.Size(50, 50),
              labelOrigin: new google.maps.Point(16, 16)
            },
          });
          const infowindow = new google.maps.InfoWindow({
            content: value.nome,
            ariaLabel: value.nome,
          });

          marker.addListener('click', () => {
            infowindow.open({
              anchor: marker,
              map:this.map,
            });
          });

          this.bounds.extend(marker.getPosition());
          marker.setMap(this.map);
          index++;
        });
        this.mapBounds();
      });
    }

  }

  mapBounds() {
    setTimeout(() => {
      this.map.fitBounds(this.bounds);
    }, 500);
    google.maps.event.addListenerOnce(this.map, 'idle', () => {});
  }
  async ngOnInit() {}
}
