import { Injectable } from '@angular/core';
import {DBSQLiteValues, SQLiteDBConnection} from '@capacitor-community/sqlite';
import { SQLiteService } from './sqlite.service';
import {Platform} from "@ionic/angular";
import {Produttore} from "./model/produttore";
import {Categoria} from "./model/categoria";
import {Prodotto} from "./model/prodotto";
import {Articolo} from "./model/articolo";
import {Proposta} from "./model/proposta";
import {Servizio} from "./model/servizio";


interface SQLiteDBConnectionCallback<T> {(myArguments: SQLiteDBConnection): T }
const DB_NAME_KEY = 'veronesex';
@Injectable()
export class DatabaseService {
  constructor(private sqlite: SQLiteService, private platform:Platform) {
  }
  isDesktop(): boolean {
    return !(this.platform.is('cordova') || this.platform.is('capacitor'));
    // return !this.platform.is('android') || !this.platform.is('ios');
  }
  async executeQuery<T>(callback: SQLiteDBConnectionCallback<T>, databaseName: string = DB_NAME_KEY): Promise<T> {
    try {
      const isConnection = await this.sqlite.isConnection(databaseName);
      if (isConnection.result) {
        const db = await this.sqlite.retrieveConnection(databaseName);
        return await callback(db);
      }
      else {
        const db = await this.sqlite.createConnection(databaseName, false, 'no-encryption', 1);
        await db.open();
        const cb = await callback(db);
        await this.sqlite.closeConnection(databaseName);
        return cb;
      }
    } catch (error) {
      // await this.sqlite.closeConnection(databaseName);
      // throw Error(`DatabaseServiceError: ${error}`);
    }
  }
  async getProduttoriVino(all: boolean = false){
    let q = "select * from produttore where tipo in (1,3) and bozza != 1 ORDER BY nome";
    if(all) {
      q = "select * from produttore where tipo in (1,3) and bozza != 1 ORDER BY nome";
    }
    return this.executeQuery<any>(async (db: SQLiteDBConnection) => {
      const products: DBSQLiteValues = await db.query(q);
      return products.values as Produttore[];
    });
  }
  async getCategorieVino(lang: string){
    return this.executeQuery<any>(async (db: SQLiteDBConnection) => {
      const items: DBSQLiteValues = await db.query("select * from categoria_prodotto where tipo in (1,3) and bozza != 1 ORDER BY nome");
      const out = items.values as Categoria[];
      out.forEach(value => {
        if(lang === 'en'){
          value.nome = value.nome_en;
        }
        else if(lang === 'de'){
          value.nome = value.nome_de;
        }
      });
      return out;
    });
  }

  async getProduttoriOlio(all: boolean = false){
    let q = "select * from produttore where tipo in (2,3) and bozza != 1 ORDER BY nome";
    if(all) {
      q = "select * from produttore where tipo in (2,3) and bozza != 1 ORDER BY nome";
    }
    return this.executeQuery<any>(async (db: SQLiteDBConnection) => {
      const products: DBSQLiteValues = await db.query(q);
      return products.values as Produttore[];
    });
  }

  async getCategorieOlio(lang: string){
    return this.executeQuery<any>(async (db: SQLiteDBConnection) => {
      const items: DBSQLiteValues = await db.query("select * from categoria_prodotto where tipo in (2,3) and bozza != 1 ORDER BY nome");
      const out = items.values as Categoria[];
      out.forEach(value => {
        if(lang === 'en'){
          value.nome = value.nome_en;
        }
        else if(lang === 'de'){
          value.nome = value.nome_de;
        }
      });
      return out;
    });
  }

  async getProdotti(tipo: number, categorie: Array<number>, produttori: Array<number>, page: number,lang: string){
    return this.executeQuery<any>(async (db: SQLiteDBConnection) => {

      let field = 'nome';
      if(lang === 'en'){field = 'nome_en';}
      else if(lang === 'de'){field = 'nome_de';}
      const perpage = 12;
      const offset = perpage*(page-1);
      let q1 = '';
      if(produttori.length > 0){
        q1 = 'AND produttore in ('+produttori.join(',')+')';
      }
      let q2 = '';
      if(categorie.length > 0){
        q2 = 'AND id IN (SELECT item FROM prodotto_categorie WHERE categoria in ('+categorie.join(',')+') )';
      }
      const q = 'select * from `prodotto` where `bozza` != 1 and `tipo` = '+tipo+' '+q2+' '+q1+' order by '+
        field+' asc limit '+perpage+' offset '+offset;

      const qCount = 'select * from `prodotto` where `bozza` != 1 and `tipo` = '+tipo+' '+q2+' '+q1+' order by '+
        field+' asc ';

      const itemsCount: DBSQLiteValues = await db.query(qCount);
      const count = itemsCount.values.length;
      let hasNext = true;
      if(offset+perpage >= count){
        hasNext = false;
      }
      const items: DBSQLiteValues = await db.query(q);
      const out = items.values as Prodotto[];
      out.forEach(value => {
        if(lang === 'it'){
          value.nomeDaVisualizzare = value.nome
          value.sottotitoloDaVisualizzare = value.sottotitolo
          // value.nome = value.nome;
          // value.sottotitolo = value.sottotitolo;
        }
        if(lang === 'en'){
          value.nomeDaVisualizzare = value.nome_en
          value.sottotitoloDaVisualizzare = value.sottotitolo_en
          // value.nome = value.nome_en;
          // value.sottotitolo = value.sottotitolo_en;
        }
        else if(lang === 'de'){
          value.nomeDaVisualizzare = value.nome_de
          value.sottotitoloDaVisualizzare = value.sottotitolo_de
          // value.nome = value.nome_de;
          // value.sottotitolo = value.sottotitolo_de;
        }
      });
      const ret = {
        items:out, hasNext: hasNext
      };
      return ret;
    });
  }

  async getDataHome(lang: string){
    return this.executeQuery<any>(async (db: SQLiteDBConnection) => {

      const vini: DBSQLiteValues = await db.query("select * from prodotto where tipo = 1 and bozza != 1 ORDER BY RANDOM() LIMIT 6");
      const oli: DBSQLiteValues = await db.query("select * from prodotto where tipo = 2 and bozza != 1 ORDER BY RANDOM() LIMIT 6");

      const cantine: DBSQLiteValues = await db.query("select * from produttore where tipo in (1,3) and bozza != 1 ORDER BY RANDOM() LIMIT 6");
      const frantoi: DBSQLiteValues = await db.query("select * from produttore where tipo in (2,3) and bozza != 1 ORDER BY RANDOM() LIMIT 6");
      // const pois: DBSQLiteValues = await db.query("select * from produttore where tipo in (2,3) ORDER BY RANDOM() LIMIT 6");
      const articoli: DBSQLiteValues = await db.query("select * from articolo where tipo = 1 and bozza != 1  ORDER BY RANDOM() LIMIT 8");
      const proposte:  DBSQLiteValues = await db.query("select * from articolo where tipo = 3 and bozza != 1  ");

      const ret = {
        pois:[],

        cantine:cantine.values as Produttore[],
        frantoi:frantoi.values as Produttore[],
        vini:vini.values as Prodotto[],
        oli:oli.values as Prodotto[],
        articoli:articoli.values as Articolo[],
        proposte:proposte.values as Proposta[],
      };
      return ret;
    });
  }

  async getProposte(lang: string){
    return this.executeQuery<any>(async (db: SQLiteDBConnection) => {
      // console.log("select * from articolo where tipo = 3  ");
      const proposte:  DBSQLiteValues = await db.query("select * from articolo where tipo = 3 and bozza != 1  ");
      const out = proposte.values as Proposta[];
      const ret = {
        proposte:[],
        gusto:[],
        offerte:[],
      };
      out.forEach(value => {
        if(lang === 'it'){
          value.titoloToShow = value.titolo;
          value.sottotitoloToShow = value.sottotitolo;
          // value.titolo = value.titolo;
          // value.sottotitolo = value.sottotitolo;
        }
        if(lang === 'en'){
          value.titoloToShow = value.titolo_en;
          value.sottotitoloToShow = value.sottotitolo_en;
          // value.titolo = value.titolo_en;
          // value.sottotitolo = value.sottotitolo_en;
        }
        else if(lang === 'de'){
          value.titoloToShow = value.titolo_de;
          value.sottotitoloToShow = value.sottotitolo_de;
          // value.titolo = value.titolo_de;
          // value.sottotitolo = value.sottotitolo_de;
        }
        if (value.sezione == 1) {
          ret.proposte.push(value);
        } else if (value.sezione == 2) {
          ret.gusto.push(value);
        } else if (value.sezione == 3) {
          ret.offerte.push(value);
        }
      });
      return ret;
    });
  }
  async getServizi(type: number, lang: string, categorie: Array<number>){
    return this.executeQuery<any>(async (db: SQLiteDBConnection) => {
      // console.log("select * from servizio where tipo = "+type);
      let q3 = '';
      if(categorie.length > 0){
        q3 = ' AND id IN (SELECT item FROM servizio_categorie WHERE categoria in ('+categorie.join(',')+') ) ';
      }
      // const servizi:  DBSQLiteValues = await db.query("select * from servizio  where tipo =  "+type);

      const q = 'select * from servizio where bozza != 1 and tipo = '+type+' '+q3+' ORDER BY id ASC';
      const servizi:  DBSQLiteValues = await db.query(q);
      const out = servizi.values as Servizio[];
      out.forEach(value => {
        if(lang === 'en'){
          value.excerpt = value.excerpt_en;
        }
        else if(lang === 'de'){
          value.excerpt = value.excerpt_de;
        }

      });
      return out;
    });
  }

  async getCategorieArticolo(tipo: number, lang: string){
    return this.executeQuery<any>(async (db: SQLiteDBConnection) => {
      const items: DBSQLiteValues = await db.query("select * from categoria_articolo where tipo = "+tipo+" and bozza != 1  ORDER BY nome");
      const out = items.values as Categoria[];
      out.forEach(value => {
        if(lang === 'it') {
          value.nomeDaVisualizzare = value.nome
          // value.nome = value.nome;
        }
        if(lang === 'en'){
          value.nomeDaVisualizzare = value.nome_en
          // value.nome = value.nome_en;
        }

        else if(lang === 'de'){
          value.nomeDaVisualizzare = value.nome_de
          // value.nome = value.nome_de;
        }
      });
      return out;
    });
  }

  async getEventi(datestart: string, dateend: string, categorie: Array<number>, lang:string){
    let q1 = '';
    let q2 = '';

    let q3 = '';
    if(datestart != null && datestart !== ''){
      q1 = ' AND data_fine >= "'+datestart+'" ';
    }
    if(dateend != null && dateend !== ''){
      q2 = ' AND data_inizio <= "'+dateend+'" ';
    }
    if(categorie.length > 0){
      q3 = ' AND id IN (SELECT item FROM articolo_categorie WHERE categoria in ('+categorie.join(',')+') ) ';
    }
    const q = 'select * from articolo where bozza != 1 and tipo = 2 '+q1+q2+q3+' ORDER BY id';

    return this.executeQuery<any>(async (db: SQLiteDBConnection) => {
      const items: DBSQLiteValues = await db.query(q);
      const out = items.values as Articolo[];
      out.forEach(value => {
        if(lang === 'en'){
          value.titolo = value.titolo_en;
        }
        else if(lang === 'de'){
          value.titolo = value.titolo_de;
        }
      });
      return out;
    });

  }
  async getArticoliFiltro(categorie: Array<number>, lang: string){
    let q3 = '';
    if(categorie.length > 0){
      q3 = ' AND id IN (SELECT item FROM articolo_categorie WHERE categoria in ('+categorie.join(',')+') ) ';
    }
    const q = 'select * from articolo where bozza != 1 and tipo = 1 '+q3+' ORDER BY id ASC';
    return this.executeQuery<any>(async (db: SQLiteDBConnection) => {
      const items: DBSQLiteValues = await db.query(q);
      const out = items.values as Articolo[];
      out.forEach(value => {
        if(lang === 'en'){
          value.titolo = value.titolo_en;
        }
        else if(lang === 'de'){
          value.titolo = value.titolo_de;
        }
      });
      return out;
    });
  }

  async getProposteFiltro(sezione: number, lang: string){

    const q = 'select * from articolo where tipo = 3 AND sezione = '+sezione+' and bozza != 1 ORDER BY id ASC';
    return this.executeQuery<any>(async (db: SQLiteDBConnection) => {
      const items: DBSQLiteValues = await db.query(q);
      const out = items.values as Articolo[];
      out.forEach(value => {
        if(lang === 'en'){
          value.titolo = value.titolo_en;
        }
        else if(lang === 'de'){
          value.titolo = value.titolo_de;
        }
      });
      return out;
    });
  }

  async getCategorieServizio(tipo: number, lang: string){
    return this.executeQuery<any>(async (db: SQLiteDBConnection) => {
      const items: DBSQLiteValues = await db.query("select * from categoria_servizio where tipo = "+tipo+" ORDER BY nome");
      const out = items.values as Categoria[];
      out.forEach(value => {
        if(lang === 'it'){
          value.nomeDaVisualizzare = value.nome
          // value.nome = value.nome;
        }
        if(lang === 'en'){
          value.nomeDaVisualizzare = value.nome_en
          // value.nome = value.nome_en;
        }

        else if(lang === 'de'){
          value.nomeDaVisualizzare = value.nome_de
          // value.nome = value.nome_de;
        }
      });
      return out;
    });
  }
}

