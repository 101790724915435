import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {PreferitiService} from "../../preferiti.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentAvatar = 'assets/images/profile2.svg';

  constructor(private router: Router) { }

  ngOnInit() {

    this.currentAvatar = PreferitiService.currentAvatar;
    // console.log(this.currentAvatar);
    // console.log(PreferitiService.currentAvatar);
  }

  navigateToHome() {
    this.router.navigate(['tabs/home']);
  }

  navigateToSearch(){
    this.router.navigate(['ricerca']);
  }
}
